import { useApiClient } from '../useApiClient';
import { SidebarNavigation } from '@/json_schema_types/sidebar_navigation';
import { ref } from 'vue';

// Global state
export const navigationData = ref<SidebarNavigation | undefined>();

export function useSidebarNavigationMutator(navigationPath: string) {
  const { apiClient } = useApiClient();

  const fetchNavigationItems = async () => {
    try {
      const response = await apiClient.get(navigationPath);
      navigationData.value = response.data;
    } catch (error) {
      window.location.href = '/connection_error';
    }
  };

  return {
    navigationData,
    fetchNavigationItems,
  };
}
