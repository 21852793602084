<script lang="ts">
export default {
  name: 'NotificationsDropdown',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, ref, watch } from 'vue';
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
import { UtilityNavItems } from '@/json_schema_types/sidebar_navigation';
import NavigationHeaderItem from '@/components/atoms/NavigationHeaderItem/Index.vue';
import PopoverMenu from '@/components/atoms/Popover/PopoverMenu/Index.vue';

interface Props {
  utilityNavItems: UtilityNavItems;
}
const props = defineProps<Props>();

const { t } = useI18n();
const { apiClient } = useApiClient();
const notificationsButton = ref<HTMLElement | null>(null);
const notificationsDropdownOpen = ref(false);
const loading = ref(true);
const notifications = ref();
const userNotificationCount = ref(props.utilityNavItems.notifications.count);
const unreadCount = ref(props.utilityNavItems.notifications.unread_count);

const getData = () => {
  apiClient
    .get(props.utilityNavItems.notifications.endpoint, { params: { per: 5 } })
    .then((response) => {
      notifications.value = response.data;
      unreadCount.value = 0;
      userNotificationCount.value = 0;
      loading.value = false;
    });
};

watch(notificationsDropdownOpen, (isOpen) => {
  if (isOpen && !notifications.value) {
    getData();
  }
});
</script>

<template>
  <NavigationHeaderItem
    ref="notificationsButton"
    :tool-tip-text="t('activerecord.models.notification.other')"
    :aria-label="t('activerecord.models.notification.other')"
    aria-haspopup="true"
    :aria-expanded="notificationsDropdownOpen"
    :icon="utilityNavItems.notifications.icon"
    aria-controls="add-new-popover-menu"
    :badge-count="userNotificationCount"
    class="user-account-menu__button"
    data-test-id="user-account-menu-button"
    @click.prevent="notificationsDropdownOpen = !notificationsDropdownOpen"
    @keyup.enter="notificationsDropdownOpen = !notificationsDropdownOpen"
  >
  </NavigationHeaderItem>
  <PopoverMenu
    v-if="notificationsDropdownOpen"
    data-test-id="user-account-menu-popover_notifications"
    position="bottom"
    :toggle-element-selector="notificationsButton"
    :model-value="notificationsDropdownOpen"
    right="15rem"
    class="tw-max-h-[calc(100vh-70px)] tw-overflow-y-auto"
    @update:model-value="notificationsDropdownOpen = $event"
  >
    <template v-if="loading">
      <div
        v-for="i in 3"
        :key="i"
        class="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-text-xl tw-text-gray-800"
        :class="{ 'tw-border-b-solid tw-border-thin tw-border-gray-200': i !== 3 }"
      >
        <div class="tw-h-6 skeleton-loading"></div>
        <div class="tw-h-6 skeleton-loading"></div>
        <div class="tw-h-6 tw-w-[40%] skeleton-loading"></div>
      </div>
    </template>
    <div v-else-if="notifications.length === 0" class="tw-p-12 tw-text-center">
      <span class="tw-text-muted tw-text-center tw-m-y-2">
        {{ t('vue_templates.notifications_navigation.no_notifications') }}
      </span>
    </div>
    <template v-else>
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-text-xl tw-text-gray-800 tw-border-b-solid tw-border-thin tw-border-gray-200"
      >
        <div v-html="notification.sentence" />
        <div class="tw-text-gray-500">
          {{ notification.created_at }}
        </div>
      </div>
      <div class="tw-p-4 tw-pb-0 tw-text-center">
        <a :href="utilityNavItems.notifications.url">
          {{ t('vue_templates.notifications_navigation.view_all') }}
        </a>
      </div>
    </template>
  </PopoverMenu>
</template>
