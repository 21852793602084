import { InjectionKey } from 'vue';

export interface ISearchData {
  recentSearchesPath: string; // Used to GET an array of recent search terms
  searchesPath: string; // Used to POST a new search term
}

export const searchDataKey = Symbol() as InjectionKey<ISearchData>;

export const navigationPathKey = Symbol() as InjectionKey<string>;

export interface ILanguageLink {
  language: string;
  locale: string;
  in_english: string;
  url: string;
}

export const languageLinksKey = Symbol() as InjectionKey<ILanguageLink[]>;

export type WhiteNavStyleToggle = boolean;

export const whiteNavStyleToggleKey = Symbol() as InjectionKey<WhiteNavStyleToggle>;
